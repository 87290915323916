import React from "react"
import "./icon-boxes.css"

interface iconBoxesProps {}

export const IconBoxes: React.FC<iconBoxesProps> = ({}) => {
  return (
    <div className="row icon-boxes mb-5">
      <div
        className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
        data-aos="zoom-in"
        data-aos-delay="200"
      >
        <div className="icon-box">
          <div className="icon">
            <i className="ri-stack-line"></i>
          </div>
          <h4 className="title">
            <a href="#about-video" style={{ textDecoration: "none" }}>
              Social Security and Disaster Relief
            </a>
          </h4>
          <p className="description">
            Food sourcing and delivery, fire and natural disaster relief
          </p>
        </div>
      </div>

      <div
        className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
        data-aos="zoom-in"
        data-aos-delay="400"
      >
        <div className="icon-box">
          <div className="icon">
            <i className="ri-command-line"></i>
          </div>
          <h4 className="title">
            <a href="#about-video" style={{ textDecoration: "none" }}>
              Early Childhood Development (ECD)
            </a>
          </h4>
          <p className="description">
            Strengthening Early Childhood Development (ECD) and Child Care
          </p>
        </div>
      </div>

      <div
        className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
        data-aos="zoom-in"
        data-aos-delay="300"
      >
        <div className="icon-box">
          <div className="icon">
            <i className="ri-palette-line"></i>
          </div>
          <h4 className="title">
            <a href="#about-video" style={{ textDecoration: "none" }}>
              Gender Based Violence (GBV)
            </a>
          </h4>
          <p className="description">
            Strengthening Prevention and Early Intervention on Gender Based
            Violence (GBV) and Femicide
          </p>
        </div>
      </div>

      <div
        className="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
        data-aos="zoom-in"
        data-aos-delay="500"
      >
        <div className="icon-box">
          <div className="icon">
            <i className="ri-fingerprint-line"></i>
          </div>
          <h4 className="title">
            <a href="#about-video" style={{ textDecoration: "none" }}>
              Growing and Strengthening NPO sector
            </a>
          </h4>
          <p className="description">
            Training, improving, monitoring and management
          </p>
        </div>
      </div>
    </div>
  )
}
