import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import "./about.css"

interface AboutProps {}

const About: React.FC<AboutProps> = ({}) => {
  return (
    <>
      <section id="about" className="about">
        <div className="container" data-aos="fade-up">
          <div className="section-title">
            <h2>About Us</h2>
          </div>

          <div className="row content">
            <div className="col-lg-4">
              <p className="text-justify">
                South African Community of Faith-based Fraternals and
                Federations (SACOFF) is an organisation of faith-based
                organisations whose love for the communities in South Africa is
                actioned through various community-based initiatives and
                projects. SACOFF aims to empower vulnerable individuals, groups
                and communities to become capable and self-reliant participants
                in their own development. The aim of all members is continuously
                ensuring the empowerment and sustainability of communities in
                the field of social security and development.
              </p>
            </div>
            <div className="col-lg-4 pt-4 pt-lg-0">
              <p className="text-justify">
                SACOFF empowers faith-based organisations all over South Africa
                (even the most rural) to have representation in regional,
                provincial and national structures and have national competency.
                SACOFF aims to facilitate, strengthen, advance and mobilise
                faith-based, religious and spiritual leaders all over the
                country in the common goal of community development avoiding
                religious politics and doctrinal differences. SACOFF is set up
                in such a way that no one religious organisation or group of
                individuals owns the structure.
              </p>
            </div>
            <div className="col-lg-4 pt-4 pt-lg-0">
              <p className="text-justify">
                SACOFF have established collaborations and partnerships with
                various National structures to reach common goals. SACOFF is
                currently working with the South African Police Services (SAPS)
                in Spiritual Crime Prevention, the National House of Traditional
                and Khoi-San leaders (NHT&KL), the Civilian Secretariate for
                Police Service (CSPS) and many other civil society
                organisations.
              </p>
              <p className="text-justify">
                As of date SACOFF has 216 registered members representing 15,459
                churches.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="about-video" className="about-video">
        <div className="container" data-aos="fade-up">
          <div className="row">
            <div
              className="col-lg-6 video-box align-self-baseline"
              data-aos="fade-right"
              data-aos-delay="100"
            >
              <StaticImage
                src={"../../images/about-img.jpg"}
                alt={"About Image"}
                width={520}
                height={420}
              />
              <a
                href="https://youtu.be/p6CMxtmjFc0"
                className="venobox play-btn mb-4"
                data-vbtype="video"
                data-autoplay="true"
              ></a>
            </div>

            <div
              className="col-lg-6 pt-3 pt-lg-0 content"
              data-aos="fade-left"
              data-aos-delay="100"
            >
              <h3>Social Environments and Common Objectives</h3>
              <p className="font-italic">
                We aim to continuously ensure the empowerment and sustainability
                of communities’ in the field of social security and development.
              </p>
              <ul>
                <li>
                  <i className="bx bx-check-double"></i> Social Security and
                  Disaster Relief - Food sourcing and delivery, fire and natural
                  disaster relief
                </li>
                <li>
                  <i className="bx bx-check-double"></i> Early Childhood
                  Development (ECD) - Strengthening Early Childhood Development
                  (ECD) and Child Care.
                </li>
                <li>
                  <i className="bx bx-check-double"></i> Gender Based Violence
                  (GBV) - Strengthening Prevention and Early Intervention on
                  Gender Based Violence (GBV) and Femicide
                </li>
                <li>
                  <i className="bx bx-check-double"></i> Growing and
                  Strengthening NPO sector - Training, improving, monitoring and
                  management
                </li>
                <li>
                  <i className="bx bx-check-double"></i>
                  Strengthening Social Partnership - Communities becoming
                  capable and self-reliant participants in their own
                  development.
                </li>
                <li>
                  <i className="bx bx-check-double"></i>
                  Crime Prevention & Anti-Bullying Projects
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
export default About
